import {KeycloakConfig} from 'keycloak-js';

interface IConfig {
    keycloak: KeycloakConfig
}

export let config: IConfig = {
    keycloak: {
        realm: `${process.env.REACT_APP_ENVIRONMENT}-qa-community`,
        url: '/auth/',
        clientId: 'community-ui'
    }
}
