
import { IPathway } from '../models/pathway';
import { ICourse } from '../models/course';
import { IModule } from '../models/module';
import { toast } from 'react-toastify';
import Axios, {AxiosResponse} from 'axios';
import {CommunityAPI} from './community-api.service';

export const fetchPathways = (token?: string, displayError = true): Promise<IPathway[]> => {
    return CommunityAPI.get('courseware/pathways.json', token).then( response => {
        return response.data
    }).catch(handleFetchErrors(displayError));
    // return API.get('api', '/courseware/pathways.json', {}).catch(handleFetchErrors(displayError));
};

export const fetchCourses = (displayError = true): Promise<ICourse[]> => {
    return Axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/api/courseware/courses.json`).then( response => {
        return response.data
    }).catch(handleFetchErrors(displayError));
    // return API.get('api', '/courseware/courses.json', {}).catch(handleFetchErrors(displayError));
};


export const fetchModules = (displayError = true): Promise<IModule[]> => {
    return Axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/api/courseware/modules.json`).then( response => {
        return response.data
    }).catch(handleFetchErrors(displayError));
    // return API.get('api', '/courseware/modules.json', {}).catch(handleFetchErrors(displayError));
};

export const fetchResource = (resourcePath: string, token?: string, displayError = true): Promise<AxiosResponse> => {
    return CommunityAPI.get('resources', resourcePath, 'blob').catch(handleFetchErrors(displayError));
};

// Handles fetch errors, displays them on the screen as a toast message and throws the error down.
const handleFetchErrors = (displayError: boolean) => (error: any) => {
    if (displayError) {
        toast(`The application couldn't fetch something. Please try again`, { type: 'error' });
    }
    throw error;
};
