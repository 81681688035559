import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import appStore from './redux/app.store';
import { toast } from 'react-toastify';
import Keycloak from 'keycloak-js';
import {config} from './config';


toast.configure({
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    className: 'toast-override',
    bodyClassName: 'toast-override__body',
    progressClassName: 'toast_override__progress'
});

const keycloak = Keycloak(config.keycloak)

keycloak.onTokenExpired = () => {
    keycloak.updateToken(30)
}

keycloak.init({ onLoad: 'login-required' }).then((auth) => {
    if (!auth) {
        window.location.reload();
    } else {
        ReactDOM.render(
            <Provider store={appStore}>
                <App keycloakInstance={keycloak} />
            </Provider>,
            document.getElementById('root')
        );
    }
})



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
