import Axios, {AxiosResponse} from 'axios';



export const CommunityAPI = {
    get: (resource: string, token?: string,
        responseType:'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream' | undefined  = 'json'
    ): Promise<AxiosResponse> => {
        const apiURL = process.env.REACT_APP_ENDPOINT_URL ? process.env.REACT_APP_ENDPOINT_URL : '/api'
        return Axios.get(`${apiURL}/${resource}`, {
            headers: {
                Authorization: 'Bearer ' + token
            },
            responseType
        })
    }
}
