import React, { Component } from 'react'
import PageBase from '../../layout/components/PageBase';
import { Row, Col } from 'react-bootstrap';
import PageSidebar from '../../layout/components/sidebar/PageSidebar';
import { IPathway } from '../../common/models/pathway';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ISidebarSection } from '../../common/models/sidebarSection';
import { IChallenge, ChallengeToSidebarSection } from '../../common/models/challenge';
import { IState } from '../../redux/app.store';
import { connect } from 'react-redux';
import MarkdownViewer from './MarkdownViewer';
import {KeycloakInstance} from 'keycloak-js';
import {CommunityAPI} from '../../common/services/community-api.service';

interface IChallengesPageRouteParams {
    sidebarSectionRef: string;
    pathwayName: string;
}

interface IChallengesPageProps extends RouteComponentProps<IChallengesPageRouteParams> {
    currentPathway?: IPathway;
    keycloakInstance?: KeycloakInstance
}

interface IChallengesPageState {
    sidebarSections: ISidebarSection[];
    markdown: string;
    keycloakInstance: KeycloakInstance;
}

class ChallengesPage extends Component<IChallengesPageProps, IChallengesPageState> {

    constructor (props: IChallengesPageProps) {
        super(props)
        if (this.props.keycloakInstance) {
            this.state = {
                sidebarSections: [],
                markdown: '',
                keycloakInstance: this.props.keycloakInstance
            };
        }
    }

    componentDidMount() {
        // Update sidebar if first load and current pathway already exists (happens if coming from another page)
        if (this.props.currentPathway) {
            const sidebarSections = this.getSidebarSections();
            this.initPageContentOnInitialRender(sidebarSections);
            this.setState({ sidebarSections: [...sidebarSections] || [], markdown: '' });
        }
    }

    componentDidUpdate(prevProps: IChallengesPageProps) {
        // Only update sidebar sections if the current pathway changes
        if (this.props.currentPathway && prevProps.currentPathway !== this.props.currentPathway) {
            const sidebarSections = this.getSidebarSections();
            this.initPageContentOnInitialRender(sidebarSections);
            this.setState({ sidebarSections: [...sidebarSections] || [], markdown: '' });
        }
    }

    /** Init the courseware page on first load. Sets the selected project based on the URL. */
    initPageContentOnInitialRender = (sections: ISidebarSection<IChallenge>[]) => {
        const section = sections.find(sect => sect.ref === this.props.match.params.sidebarSectionRef);
        if (section) {
            this.handleChallengeSelected(section);
        }
    }

    getSidebarSections() {
        // return [];
        return this.props.currentPathway?.challenges?.map(p => ChallengeToSidebarSection(p)) || [];
    }

    handleChallengeSelected = (section: ISidebarSection<IChallenge>) => {
        this.props.history.push(`/~/${this.props.match.params.pathwayName}/challenges/${section.ref}`);
        CommunityAPI.get(`courseware/${section.data.uri}`, this.state.keycloakInstance.token, 'text').then((response) => {
            this.setState({
                markdown: response.data
            });
        });
    }

    render() {
        const { sidebarSections, markdown } = this.state;
        return (
            <PageBase title="Challenges" bgColour="info">
                <Row>
                    <Col xs={12} sm={5} md={4} lg={3} xl={2}>
                        <PageSidebar sections={sidebarSections} sectionClick={this.handleChallengeSelected}/>
                    </Col>
                    <Col xs={12} sm={7} md={8} lg={9} xl={10}>
                        <MarkdownViewer markdown={markdown} />
                    </Col>
                </Row>
            </PageBase>
        )
    }
}

const mapStateToProps = (state: IState, ownProps: IChallengesPageProps): IChallengesPageProps => ({
    ...ownProps,
    currentPathway: state.app.currentPathway,
    keycloakInstance: state.app.keycloakInstance
});

export default withRouter(
    connect(mapStateToProps, {})(ChallengesPage)
)
