import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import PageBase from '../../layout/components/PageBase';
import { API } from 'aws-amplify';

interface IPodcast {
    title: string;
    date: string;
    url: string;
}

interface IEvent {
    title: string;
    address: string;
    date: string;
    time: string;
    url: string;
}

interface IBlog {
    title: string;
    author: string;
    url: string;
    date: string;
}

interface ISocialPageState {
    podcasts: IPodcast[],
    events: IEvent[],
    blogs: IBlog[]
}

class SocialPage extends React.Component<{}, ISocialPageState> {
    constructor(props: any) {
        super(props)
        this.state = {
            podcasts: [],
            events: [],
            blogs: []
        };
    }
    public componentDidMount() {
        Promise.all([
            API.get('api', `/social/podcasts.json`, { responseType: 'json' }),
            API.get('api', `/social/events.json`, { responseType: 'json' }),
            API.get('api', `/social/blogs.json`, { responseType: 'json' })
        ])
        .then(([podcasts, events, blogs]) => this.setState({podcasts, events, blogs}));
    }
    private getPodcasts() {
        const podcasts = this.state.podcasts
        const domPodcasts = []
        if (podcasts) {
            for (const podcast of podcasts) {
                domPodcasts.push(
                    <Row className="social-page__item" key={podcast.title}>
                        <Col xs={12} sm={7}>
                        <a href={podcast.url} target="_blank" rel="noopener noreferrer">{podcast.title}</a>
                        </Col>
                        <Col xs={12} sm={5}>
                            <div className="social-page__secondary">
                                <p>{podcast.date}</p>
                            </div>
                        </Col>
                    </Row>
                )
            }
        }
        return domPodcasts
    }
    private getEvents() {
        const events = this.state.events
        const domEvents = []
        if (events) {
            for (const event of events) {
                let splitAddress = event.address.split(',')
                // trim all elements in array
                splitAddress = splitAddress.map(Function.prototype.call, String.prototype.trim)
                const addressDom = []
                for (const line of splitAddress) {
                    addressDom.push(<p className="social-page__address-line">{line}</p>)
                }
                domEvents.push(
                    <Row className="social-page__item" key={event.title}>
                        <Col xs={12} sm={7}>
                        <a href={event.url} target="_blank" rel="noopener noreferrer">{event.title}</a>
                        <p>{addressDom}</p>
                        </Col>
                        <Col xs={12} sm={5}>
                            <div className="social-page__secondary">
                                <p>{event.date}</p>
                                <p>{event.time}</p>
                            </div>
                        </Col>
                    </Row>
                )
            }
        }
        return domEvents
    }

    private getBlogs() {
        const blogs = this.state.blogs
        const domBlogs = []
        if (blogs) {
            for (const blog of blogs) {
                domBlogs.push(
                    <Row className="social-page__item">
                        <Col xs={12} sm={7}>
                            <a href={blog.url} rel="noopener noreferrer" target="_blank">{blog.title}</a>
                        </Col>
                        <Col xs={12} sm={5}>
                            <div className="social-page__secondary">
                                <p>{blog.author}</p>
                                <p>{blog.date}</p>
                            </div>
                        </Col>
                    </Row>
                )
            }
        }
        return domBlogs
    }

    public render() {
        return (
            <PageBase bgColour={'danger'} title={'Social'}>
                <Row>
                    <Col xs={12} md={6} lg={4}>
                        <Card>
                            <h2 className="social-page__heading">Podcasts</h2>
                            {this.getPodcasts()}
                        </Card>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <Card>
                            <h2 className="social-page__heading">Events</h2>
                            {this.getEvents()}
                        </Card>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <Card>
                            <h2 className="social-page__heading">Blogs</h2>
                            {this.getBlogs()}
                        </Card>
                    </Col>
                </Row>
            </PageBase>
        );
    }
}

export default SocialPage
