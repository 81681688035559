import React, { PropsWithChildren, ReactNode } from 'react';
import { Container } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

interface IPageBaseProps {
    /** Large title that is displayed at the top of the page */
    title: string;
    /** Smaller subtitle that appears below the title */
    subtitle?: string;
    /** Title background colour. Any theme defined colour is valid. EG: primary, info */
    bgColour?: string;
    // Extra items to appear to the right of the title
    headerExtras?: ReactNode
};

const PageBase: React.FC<PropsWithChildren<IPageBaseProps>> = (props) => {
    const bgClass = `page-base__bg ${props.bgColour ? `bg-${props.bgColour}` : ''}`;

    return (
        <div className="page-base">
            <Container fluid>
                <div className="page-base__header-container">
                    <div className="page-base__title-container">
                        <h1 className="page-base__title headline">
                            {props?.title || <span style={{ opacity: 0.25 }}><Skeleton width={400} /></span>}
                        </h1>
                        {
                            props?.subtitle && (
                                <h4 className="page-base__subtitle headline">{props?.subtitle}</h4>
                            )
                        }
                    </div>
                    <div className="page-base__header-extras">
                        {props?.headerExtras || []}
                    </div>
                </div>
                {props?.children}
            </Container>
            <div className={bgClass} />
        </div>
    );
};

export default PageBase;
