import React from 'react';
import { Dropdown, Col, Row, Button, OverlayTrigger, Tooltip, Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import MarkdownViewer from './MarkdownViewer';
import PageBase from '../../layout/components/PageBase';
import PageSidebar from '../../layout/components/sidebar/PageSidebar';
import { CourseToSidebarSection } from '../../common/models/course';
import { IModule } from '../../common/models/module';
import { IPathway } from '../../common/models/pathway';
import { ISidebarItem } from '../../common/models/sidebarItem';
import { ISidebarSection } from '../../common/models/sidebarSection';
import { setCurrentPathway } from '../../redux/actions/app.actions';
import { IState } from '../../redux/app.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFilm } from '@fortawesome/free-solid-svg-icons'
// import VideoPlayer from './VideoPlayer';
import videojs from 'video.js';
import {CommunityAPI} from '../../common/services/community-api.service';
import {KeycloakInstance} from 'keycloak-js';

interface ICoursewarePageState {
    sidebarSections: ISidebarSection[];
    markdown: string;
    currentModule?: IModule;
    showVideo: boolean;
    videoSources?: videojs.Tech.SourceObject[];
    userGroups: string[];
    keycloakInstance: KeycloakInstance
}

interface ICoursewarePageRouteParams {
    sidebarSectionRef: string;
    sidebarItemRef: string;
    pathwayName: string;
}

interface ICoursewarePageProps extends RouteComponentProps<ICoursewarePageRouteParams> {
    currentPathway?: IPathway;
    keycloakInstance?: KeycloakInstance;
}

class CoursewarePage extends React.Component<ICoursewarePageProps, ICoursewarePageState> {
    constructor(props: ICoursewarePageProps) {
        super(props);
        if (this.props.keycloakInstance) {
            this.state = {
                sidebarSections: [],
                markdown: '',
                currentModule: undefined,
                showVideo: false,
                userGroups: [],
                keycloakInstance: this.props.keycloakInstance
            };
        }
    }

    public handleModuleSelected = (item: ISidebarItem<IModule>, section: ISidebarSection): void => {
        this.props.history.push(`/~/${this.props.match.params.pathwayName}/learning/${section.ref}/${item.ref}`);
        CommunityAPI.get(`courseware/${item.data.gitUri}/README.md`, this.state.keycloakInstance.token, 'text').then(response => {
            this.setState({ markdown: response.data, currentModule: item.data, showVideo: false, videoSources: undefined });
        })
    }

    public handleDownloadResButtonClicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { currentModule } = this.state;
        // TODO: Fetch resources on click
        if (currentModule && currentModule.resources) {
            CommunityAPI.get(
                `courseware/resources/${currentModule.resources}`,
                this.state.keycloakInstance.token,
                'blob').then((response) => {
                const resArr = currentModule.resources?.split('/') || null;
                const fileName = resArr ? resArr[resArr.length - 1] : `${currentModule.resourceName}.zip`;
                const url = URL.createObjectURL(response.data);
                const link = document.createElement('a');
                document.body.appendChild(link);
                link.download = fileName;
                link.href = url;
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            });
        }
    }

    /** Init the courseware page on first load. Sets the selected module based on the URL. */
    public initPageContentOnInitialRender = (sections: ISidebarSection[]) => {
        const section = sections.find(sect => sect.ref === this.props.match.params.sidebarSectionRef);
        if (section) {
            const item = section.items.find(i => i.ref === this.props.match.params.sidebarItemRef);
            if (item) {
                this.handleModuleSelected(item, section);
            }
        }
    }

    public componentDidUpdate(prevProps: ICoursewarePageProps) {
        // Only update sidebar sections if the current pathway changes
        if (this.props.currentPathway && prevProps.currentPathway !== this.props.currentPathway) {
            const sidebarSections = this.getSidebarSections();
            this.initPageContentOnInitialRender(sidebarSections);
            this.setState({ sidebarSections: [...sidebarSections] || [], markdown: '' });
        }
    }

    public componentDidMount() {
        // Update sidebar if first load and current pathway already exists (happens if coming from another page)
        if (this.props.currentPathway) {
            const sidebarSections = this.getSidebarSections();
            this.initPageContentOnInitialRender(sidebarSections);
            this.setState({ sidebarSections: [...sidebarSections] || [], markdown: '' });
        }
    }

    public getSidebarSections() {
        return this.props.currentPathway?.courses?.map(c => CourseToSidebarSection(c)) || [];
    }

    public handleShowVideoButtonClicked = () => {
        const { currentModule, showVideo, videoSources } = this.state;
        if (currentModule?.video) {
            this.setState({
                showVideo: !showVideo, videoSources: videoSources ? undefined : [
                    {
                        src: currentModule.video.uri,
                        type: currentModule.video.mimeType
                    }
                ]
            });
        }
    }

    public renderTooltip = (message: string) => (props: any) => (
        <Tooltip id="download-resouces-tooltip" {...props} show={props.show ? 1 : 0}>
            {message}
        </Tooltip>
    );

    public render() {
        const { sidebarSections, currentModule, showVideo, videoSources, userGroups } = this.state;

        const headerExtras = (
            <div className="courseware-page__headerExtras">
                {
                    currentModule?.cloudAcademyVideos && (
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={this.renderTooltip('Cloud Academy Videos')}
                        >
                           <Dropdown drop="left">
							  <Dropdown.Toggle variant="secondary" id="ca-videos-dropdown">
                                <img alt="Cloud Academy" className="courseware-page__ca-btn-img" src="/static/ca.png"/>
							  </Dropdown.Toggle>
							  <Dropdown.Menu>
								{
									currentModule.cloudAcademyVideos.map(video => (
                                            <Dropdown.Item target="_blank" key={video.id} href={'https://app.qa.com/course/' + video.id}>
                                                {video.name}
                                            </Dropdown.Item>
										)
									)
								}
							  </Dropdown.Menu>
							</Dropdown>
                        </OverlayTrigger>
                    )
                }
                {
                    currentModule?.resources && (
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={this.renderTooltip('Download Resources')}
                        >
                            <Button onClick={this.handleDownloadResButtonClicked} variant="secondary">
                                <FontAwesomeIcon icon={faDownload} />
                            </Button>
                        </OverlayTrigger>
                    )
                }
                {
                    (currentModule?.video && currentModule?.video.uri) && (
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={this.renderTooltip('Show Video')}
                        >
                            <Button onClick={this.handleShowVideoButtonClicked} variant="secondary">
                                <FontAwesomeIcon icon={faFilm} />
                            </Button>
                        </OverlayTrigger>
                    )
                }
            </div>
        );
        return (
            <PageBase title={'Courseware'} headerExtras={headerExtras}>
                <Row>
                    <Col xs={12} sm={5} md={4} lg={3} xl={2}>
                        <PageSidebar sections={sidebarSections} itemClick={this.handleModuleSelected} />
                    </Col>
                    <Col xs={12} sm={7} md={8} lg={9} xl={10}>
                        {
                            showVideo && (
                                <Card className="mb-4 overflow-hidden">
                                    {/* <VideoPlayer sources={videoSources} /> */}
                                </Card>
                            )
                        }
                        <MarkdownViewer markdown={this.state.markdown} />
                    </Col>
                </Row>
            </PageBase>
        );
    }
}

const mapStateToProps = (state: IState, ownProps: ICoursewarePageProps): ICoursewarePageProps => {
    return {
        ...ownProps,
        currentPathway: state.app.currentPathway,
        keycloakInstance: state.app.keycloakInstance
    };
};

export default withRouter(
    connect(mapStateToProps, { setCurrentPathway })(CoursewarePage)
);
