import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import marked from 'marked';
import hljs from 'highlight.js';
import DOMPurify from 'dompurify';

type MarkdownViewerProps = {
    /** Raw markdown to be displayed */
    markdown: string
};

const MarkdownViewer: React.FC<MarkdownViewerProps> = (props) => {
    const [markdown, setMarkdown] = useState('');
    const [parsedMarkdown, setParsedMarkdown] = useState<{ __html: string }>({ __html: '' });

    if (markdown !== props.markdown) {
        setParsedMarkdown({
            __html: marked(props.markdown, {
                gfm: true,
                breaks: true,
                sanitizer: (text) => DOMPurify.sanitize(text),
                langPrefix: 'hljs ',
                highlight: (code) => hljs.highlightAuto(code).value
            })
        });
        setMarkdown(props.markdown);
    }

    return (
        <Card>
            {
                parsedMarkdown?.__html ?
                <div>
                    <div className="markdown-viewer__content" dangerouslySetInnerHTML={parsedMarkdown} />
                </div>
                :
                <div className="markdown-viewer__content empty">
                    <h4>Pick a module from the left panel</h4>
                </div>
            }
        </Card>
    );
};

export default MarkdownViewer;
