import React from 'react';
import { Dropdown, Button } from 'react-bootstrap';
import UserMenuIcon from './UserMenuIcon';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {KeycloakInstance, KeycloakLogoutOptions} from 'keycloak-js';
import {IState} from '../../../redux/app.store';
import {connect} from 'react-redux';
import {setCurrentPathway} from '../../../redux/actions/app.actions';

interface IUserMenuProps extends RouteComponentProps {
    keycloakInstance?: KeycloakInstance
}

interface IUserMenuState {
    keycloakInstance: KeycloakInstance
}

class UserMenu extends React.Component<IUserMenuProps, IUserMenuState> {

    constructor(props: IUserMenuProps) {
        super(props);
        if (this.props.keycloakInstance) {
            this.state = {
                keycloakInstance: this.props.keycloakInstance
            }
        }
        this.handleLogoutClick = this.handleLogoutClick.bind(this)
    }


    handleLogoutClick() {
        this.state.keycloakInstance.logout({})
    }

    render() {
        return (
            <>
                <Dropdown alignRight className="user-menu__icon-button">
                    <Dropdown.Toggle id="user-dropdown" as={UserMenuIcon}>
                        User
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {/* <Dropdown.Item href="#/action-2">Change Password</Dropdown.Item>
                <Dropdown.Divider /> */}
                        <Dropdown.Item onClick={this.handleLogoutClick} className="text-danger">Logout</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <div className="user-menu__collapse-content">
                    <hr />
                    <Button variant="link" className="text-danger" onClick={this.handleLogoutClick}>Logout</Button>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: IState, ownProps: IUserMenuProps): IUserMenuProps => {
    return {
        ...ownProps,
        keycloakInstance: state.app.keycloakInstance
    };
};

export default withRouter(
    connect(mapStateToProps, { setCurrentPathway })(UserMenu)
);


