import React from 'react';

type SidebarItemDotProps = {
    active?: boolean,
    end?: boolean

};

const SidebarItemDot: React.FC<SidebarItemDotProps> = ({ active = false, end = false }) => {

    const classes = [
        'sidebar-item-dot',
        active && 'active',
        active && end && 'end'
    ].join(' ');

    return (
        <div className={classes}>
            <div className="bar top" />
            <div className="bar bottom" />
            <div className="circle" />
        </div>
    );
};

export default SidebarItemDot;
