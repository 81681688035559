import React from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { setCurrentPathway as setCurrentPathwayAction } from '../../../redux/actions/app.actions';
import { IState } from '../../../redux/app.store';
import { IPathway } from '../../../common/models/pathway';
import UserMenu from './UserMenu';
import { withRouter, RouteComponentProps } from 'react-router-dom';

export interface IHeaderProps {
    currentPathway?: IPathway;
    setCurrentPathway?: typeof setCurrentPathwayAction;
    pathways?: IPathway[];
}

export const Header: React.FC<RouteComponentProps & IHeaderProps> = (
    { currentPathway, pathways, setCurrentPathway, history: routerHistory }
) => {

    const handlePathwaySelected = (pathway: IPathway) => () => {
        if (setCurrentPathway) {
            setCurrentPathway(pathway);
        }
    };

    const navigate = (path: string) => () => {
        routerHistory.push(path);
    }

    return (
        <Navbar bg="white" expand="lg">
            <Navbar.Brand>
                <img src="/static/qa_fill_primary.svg" height="32px" alt="QA" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link className="px-3" onClick={navigate('/~/_/learning')}>Learning</Nav.Link>
                    <Nav.Link className="px-3" onClick={navigate('/~/_/projects')}>Projects</Nav.Link>
                    <Nav.Link className="px-3" onClick={navigate('/~/_/challenges')}>Challenges</Nav.Link>
                    {/* <Nav.Link className="px-3" onClick={navigate('/pathway-designer')}>Pathway Designer</Nav.Link> */}
                </Nav>
                <NavDropdown title={currentPathway?.name || 'Pathways'} id="pathway-select-dropdown">
                    {
                        pathways && pathways.map((pathway, index) => (
                            <NavDropdown.Item key={pathway.resourceName || index} onClick={handlePathwaySelected(pathway)}>
                                {pathway?.name}
                            </NavDropdown.Item>
                        ))
                    }
                </NavDropdown>
                <UserMenu />
            </Navbar.Collapse>
        </Navbar>
    );
};

const mapStateToProps = (state: IState, ownProps: IHeaderProps) => {
    return {
        ...ownProps,
        currentPathway: state.app.currentPathway,
        pathways: state.app.pathways
    };
};

export default connect(mapStateToProps, { setCurrentPathway: setCurrentPathwayAction })(withRouter(Header));
