import { IAppState } from '../models/app-state.model';
import { IAction } from '../models/action.model';
import { Action } from 'redux';
import {
    SET_CURRENT_PATHWAY,
    SET_PATHWAYS,
    SET_INITIAL_LOADING,
    SET_KEYCLOAK_INSTANCE
} from '../actionTypes/app.action-types';
import { ISetPathways, ISetCurrentPathway, ISetInitialLoading, ISetKeycloakInstance } from '../models/app-actions.model';



const initialState: IAppState = {
    pathways: [],
    currentPathway: undefined,
    initialLoading: true,
    keycloakInstance: undefined
};

const appReducer = (state: IAppState = initialState, action: Action<string>): IAppState => {
    switch (action.type) {
        case SET_PATHWAYS:
            const { pathways } = (action as IAction<ISetPathways>).payload;
            return {
                ...state,
                pathways
            };
        case SET_CURRENT_PATHWAY:
            const { pathway: currentPathway } = (action as IAction<ISetCurrentPathway>).payload;
            return {
                ...state,
                currentPathway
            };
        case SET_INITIAL_LOADING:
            const { loading: initialLoading } = (action as IAction<ISetInitialLoading>).payload;
            return {
                ...state,
                initialLoading
            };
        case SET_KEYCLOAK_INSTANCE:
            const { keycloakInstance: keycloakInstance } = (action as IAction<ISetKeycloakInstance>).payload;
            return {
                ...state,
                keycloakInstance
            };
        default:
            return state;
    }
};

export default appReducer;
