import { ISidebarSection } from './sidebarSection';

export interface IProject {
    name: string;
    uri: string;
    markingSchemeUri: string;
    resourceName: string;
}

/**
 * Takes an input IProject and converts it into an ISidebarSection.
 * @param project Project to be converted
 */
export const ProjectToSidebarSection = (project: IProject): ISidebarSection => ({
    title: project.name,
    items: [],
    ref: project.resourceName.replace('/', '--'),
    data: project
});
