import { ISolution } from './solution';
import { ISidebarSection } from './sidebarSection';

export interface IChallenge {
    name: string;
    uri: string;
    solutions: ISolution[];
    groups: string[];
    resourceName: string;
}

export const ChallengeToSidebarSection = (challenge: IChallenge): ISidebarSection => ({
    title: challenge.name,
    items: [],
    ref: challenge.resourceName.replace('/', '--'),
    data: challenge
});
