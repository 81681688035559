import { IPathway } from '../../common/models/pathway';
import {
    SET_CURRENT_PATHWAY,
    SET_PATHWAYS,
    SET_INITIAL_LOADING,
    SET_KEYCLOAK_INSTANCE
} from '../actionTypes/app.action-types';
import {
    ISetCurrentPathway,
    ISetPathways,
    AppThunk,
    ISetInitialLoading,
    ISetKeycloakInstance
} from '../models/app-actions.model';
import { IAction } from '../models/action.model';
import { fetchPathways } from '../../common/services/pathways.service';
import {KeycloakInstance} from 'keycloak-js';


export const setCurrentPathway = (pathway: IPathway): IAction<ISetCurrentPathway> => ({
    type: SET_CURRENT_PATHWAY,
    payload: { pathway }
});

export const setInitialLoading = (loading: boolean): IAction<ISetInitialLoading> => ({
    type: SET_INITIAL_LOADING,
    payload: { loading }
});

export const setPathways = (pathways: IPathway[]): IAction<ISetPathways> => ({
    type: SET_PATHWAYS,
    payload: { pathways }
});

export const setKeycloakInstance = (keycloakInstance: KeycloakInstance): IAction<ISetKeycloakInstance> => ({
    type: SET_KEYCLOAK_INSTANCE,
    payload: { keycloakInstance }
});

export const populateAppState = (keycloakInstance: KeycloakInstance): AppThunk => (dispatch) => {
    dispatch(setInitialLoading(true));
    dispatch(setKeycloakInstance(keycloakInstance));
    Promise.all([fetchPathways(keycloakInstance.token)]).then(([pathways]) => {
        const userRoles = keycloakInstance.tokenParsed?.realm_access?.roles
        if (userRoles) {
            const pathwaysForUser = pathways.filter((pathway) => {
                return userRoles.includes(pathway.resourceName)
            })
            dispatch(setPathways(pathwaysForUser));
        } else {
            dispatch(setPathways([]));
        }
        dispatch(setInitialLoading(false));
    });
}
