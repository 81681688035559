import React, { Component } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { IPathway } from '../../common/models/pathway';
import { IState } from '../../redux/app.store';
import PageBase from '../../layout/components/PageBase';
import { Row, Col } from 'react-bootstrap';
import PageSidebar from '../../layout/components/sidebar/PageSidebar';
import { ISidebarSection } from '../../common/models/sidebarSection';
import MarkdownViewer from './MarkdownViewer';
import { ProjectToSidebarSection, IProject } from '../../common/models/project';
import {KeycloakInstance} from 'keycloak-js';
import {CommunityAPI} from '../../common/services/community-api.service';

interface IProjectsPageRouteParams {
    sidebarSectionRef: string;
    pathwayName: string;
}

interface IProjectsPageProps extends RouteComponentProps<IProjectsPageRouteParams> {
    currentPathway?: IPathway;
    keycloakInstance?: KeycloakInstance;
}

interface IProjectsPageState {
    sidebarSections: ISidebarSection[];
    markdown: string;
    keycloakInstance: KeycloakInstance;
}

class ProjectsPage extends Component<IProjectsPageProps, IProjectsPageState> {

    constructor(props: IProjectsPageProps) {
        super(props);

        if (this.props.keycloakInstance) {
            this.state = {
                sidebarSections: [],
                markdown: '',
                keycloakInstance: this.props.keycloakInstance
            };
        }
    }


    public componentDidMount() {
        // Update sidebar if first load and current pathway already exists (happens if coming from another page)
        if (this.props.currentPathway) {
            const sidebarSections = this.getSidebarSections();
            this.initPageContentOnInitialRender(sidebarSections);
            this.setState({ sidebarSections: [...sidebarSections] || [], markdown: '' });
        }
    }

    public componentDidUpdate(prevProps: IProjectsPageProps) {
        // Only update sidebar sections if the current pathway changes
        if (this.props.currentPathway && prevProps.currentPathway !== this.props.currentPathway) {
            const sidebarSections = this.getSidebarSections();
            this.initPageContentOnInitialRender(sidebarSections);
            this.setState({ sidebarSections: [...sidebarSections] || [], markdown: '' });
        }
    }

    /** Init the courseware page on first load. Sets the selected project based on the URL. */
    public initPageContentOnInitialRender = (sections: ISidebarSection<IProject>[]) => {
        const section = sections.find(sect => sect.ref === this.props.match.params.sidebarSectionRef);
        if (section) {
            this.handleProjectSelected(section);
        }
    }

    public getSidebarSections() {
        return this.props.currentPathway?.projects?.map(p => ProjectToSidebarSection(p)) || [];
    }

    handleProjectSelected = (section: ISidebarSection<IProject>) => {
        this.props.history.push(`/~/${this.props.match.params.pathwayName}/projects/${section.ref}`);
        Promise.all([
            CommunityAPI.get(`courseware/${section.data.uri}`, this.state.keycloakInstance.token, 'text'),
            CommunityAPI.get(`courseware/${section.data.markingSchemeUri}`, this.state.keycloakInstance.token, 'text')
        ]).then(([projectResponse, markingSchemeResponse]) => {
            const markingScheme = (markingSchemeResponse.status === 404) ? '' : markingSchemeResponse.data
            this.setState({
                markdown: `${projectResponse.data}\n--------\n${markingScheme}`
            });
        });
    }

    render() {
        const { sidebarSections, markdown } = this.state;
        return (
            <PageBase title={'Projects'}>
                <Row>
                    <Col xs={12} sm={5} md={4} lg={3} xl={2}>
                        <PageSidebar sections={sidebarSections} sectionClick={this.handleProjectSelected} />
                    </Col>
                    <Col xs={12} sm={7} md={8} lg={9} xl={10}>
                        <MarkdownViewer markdown={markdown} />
                    </Col>
                </Row>
            </PageBase>
        )
    }
}

const mapStateToProps = (state: IState, ownProps: IProjectsPageProps): IProjectsPageProps => ({
    ...ownProps,
    currentPathway: state.app.currentPathway,
    keycloakInstance: state.app.keycloakInstance
});

export default withRouter(
    connect(mapStateToProps, {})(ProjectsPage)
);
