import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { IAppState } from './models/app-state.model';

export interface IState {
    app: IAppState
}

/**
 * Redux Store.
 *
 * Note: The `any` type provided to `createStore` is the type of action it can dispatch.
 * I've had to tweak it from the default because I'm using Thunk. Without that change you couldn't dispatch those funky async actions.
 */
const store = createStore<IState, any, {}, {}>(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(thunk)
    )
);

export default store;
