import React from 'react';
import './common/styles/main.scss';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Header from './layout/components/header/Header';
import CoursewarePage from './learning/components/CoursewarePage';
import SocialPage from './social/components/SocialPage';
import { populateAppState, setInitialLoading } from './redux/actions/app.actions';
import { toast } from 'react-toastify';
import { IState } from './redux/app.store';
import ProjectsPage from './learning/components/ProjectsPage';
import ChallengesPage from './learning/components/ChallengesPage';
import {KeycloakInstance} from 'keycloak-js';

export interface IAppProps {
  keycloakInstance: KeycloakInstance;
  populateAppState: (keycloakInstance: KeycloakInstance) => void;
  setInitialLoading: typeof setInitialLoading;
  initialLoading: boolean;
}

export class App extends React.Component<IAppProps> {

  public componentDidMount() {
    this.props.populateAppState(this.props.keycloakInstance);

    if (process.env.NODE_ENV === 'development') {
      toast('Development build. Do not use in production!', { type: 'error' });
    }
  }

  public render() {
    return (
      <div className="App">
        {
          this.props.initialLoading ? (
            <p>Loading</p>
          ) : (
              <BrowserRouter>
                <Switch>
                  {/* Public Routes */}
                  <Route path="/">
                    <Header />
                    <Switch>
                      {/* Private Routes */}
                      <Route path="/~/:pathwayName/learning/:sidebarSectionRef?/:sidebarItemRef?">
                        <CoursewarePage />
                      </Route>
                      <Route path="/~/:pathwayName/projects/:sidebarSectionRef?">
                        <ProjectsPage />
                      </Route>
                      <Route path="/~/:pathwayName/challenges/:sidebarSectionRef?">
                        <ChallengesPage />
                      </Route>
                      <Route path="/~/:pathwayName/social">
                        <SocialPage />
                      </Route>
                      <Route path="/~/:pathwayName">
                        <CoursewarePage />
                      </Route>
                      <Route path="/">
                        {/* Redirect / to the default protected route (courseware page) */}
                        <Redirect to="/~/_/learning" />
                      </Route>
                    </Switch>
                  </Route>
                </Switch>
              </BrowserRouter>
            )
        }
      </div>
    );
  }
}

export default connect((state: IState) => ({ initialLoading: state.app.initialLoading }), { populateAppState, setInitialLoading })(App);
