import { ISidebarItem } from './sidebarItem';

export interface IModule {
    gitUri: string;
    overview: string;
    name: string;
    resourceName: string;
    enabled?: boolean;
    resources: string | null;
    video?: {
        uri: string;
        id: string;
        mimeType: string;
    }
    cloudAcademyVideos?: ICloudAcademyVideo[]
};

interface ICloudAcademyVideo {
    id: string;
    name: string;
}

/**
 * Takes an input IModule and converts it into an ISidebarItem.
 * @param module Module to be converted
 */
export const ModuleToSidebarItem = (module: IModule): ISidebarItem => {
    return {
        title: module.name,
        ref: module.resourceName.replace('/', '--'), // '/'s don't play nice with the routing.
        data: module
    };
};
