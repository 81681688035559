import React from 'react';
import { ISidebarItem } from '../../../common/models/sidebarItem';
import SidebarItemDot from './SidebarItemDot';
import { Button } from 'react-bootstrap';

type SidebarItemProps = {
    item: ISidebarItem,
    active: boolean,
    end: boolean,
    itemClick(item: ISidebarItem): void
};

const SidebarItem: React.FC<SidebarItemProps> = ({ item, itemClick, active, end }) => {
    const className = `page-sidebar__item ${active && 'active'} ${end && 'end'}`;
    const handleItemClick = (clickedItem: ISidebarItem) => () => itemClick(clickedItem);

    return (
        <div className="sidebar-item">
            <SidebarItemDot active={active} end={end} />
            <Button className={className} variant="link" block key={item.title} onClick={handleItemClick(item)}>
                {item.title}
            </Button>
        </div>
    );
};

export default SidebarItem;
