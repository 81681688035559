import React from 'react';
import { Card, Accordion } from 'react-bootstrap';
import { ISidebarSection } from '../../../common/models/sidebarSection';
import { ISidebarItem } from '../../../common/models/sidebarItem';
import SidebarSection from './SidebarSection';

type PageSidebarProps = {
    sections: ISidebarSection[] | undefined,
    itemClick?(item: ISidebarItem, section: ISidebarSection): void,
    sectionClick?(section: ISidebarSection): void,
    noSectionsLabel?: string
};

/** This element is used if no sections are provided and we're not loading */
const noSections = (label: string) => (
    <Card>
        <div className="card-header text-muted">
            {label}
        </div>
    </Card>
)


const PageSidebar: React.FC<PageSidebarProps> = ({
    sections, itemClick = () => undefined, sectionClick = () => undefined, noSectionsLabel = 'Nothing found'
}) => {
    return (
        <Card className="page-sidebar">
            <Accordion>
                {sections?.map(section => (
                    <SidebarSection key={section.ref} section={section} itemClick={itemClick} sectionClick={sectionClick} />
                ))}
                {((!sections || sections.length === 0)) && noSections(noSectionsLabel)}
            </Accordion>
        </Card>
    );
};

export default PageSidebar;
