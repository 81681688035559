import { ISidebarSection } from './sidebarSection';
import { IModule, ModuleToSidebarItem } from './module';

export interface ICourse {
    name: string;
    modules: IModule[];
    resourceName: string;
}

/**
 * Takes an input ICourse and converts it into an ISidebarSection.
 * @param course Course to be converted
 */
export const CourseToSidebarSection = (course: ICourse): ISidebarSection => ({
    title: course.name,
    items: course.modules.map(module => ModuleToSidebarItem(module)),
    ref: course.resourceName.replace('/', '--'),
    data: course
});
