import React from 'react';
import { Card, Accordion } from 'react-bootstrap';
import { ISidebarSection } from '../../../common/models/sidebarSection';
import { ISidebarItem } from '../../../common/models/sidebarItem';
import SidebarItem from './SidebarItem';
import { useParams } from 'react-router-dom';

type SidebarSectionProps = {
    section: ISidebarSection,
    itemClick(item: ISidebarItem, section: ISidebarSection): void
    sectionClick(section: ISidebarSection): void
};

type SidebarRouteParams = {
    sidebarSectionRef: string,
    sidebarItemRef: string
}

const SidebarSection: React.FC<SidebarSectionProps> = ({ section, itemClick, sectionClick }) => {
    const { sidebarSectionRef, sidebarItemRef } = useParams<SidebarRouteParams>();
    const handleItemClick = (clickedSection: ISidebarSection) => (clickedItem: ISidebarItem) => itemClick(clickedItem, clickedSection);
    const handleSectionClick = () => sectionClick(section);
    let hasActive = false;

    const orderedItems = [...section.items] // Make a copy of the items so the reverse() doesn't alter the props
        .reverse() // Reverse the items so everything after the selected item can be set to active
        .map(item => { // Create the sidebar item components, set active / end
            const match = item.ref === sidebarItemRef;
            hasActive = match || hasActive;
            return (
                <SidebarItem key={item.ref} item={item} itemClick={handleItemClick(section)} active={hasActive} end={match} />
            );
        })
        .reverse(); // Flip the components back to their correct order

    return (
        <Card onClick={handleSectionClick}>
            {/*<Accordion.Toggle as={Card.Header} eventKey={section.ref} className={sidebarSectionRef === section.ref && 'active'}>*/}
            <Accordion.Toggle as={Card.Header} eventKey={section.ref} className="active">
                {section.title}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={section.ref}>
                <>{orderedItems}</>
            </Accordion.Collapse>
        </Card>
    );
};

export default SidebarSection;
